import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Router } from "react-router-dom";
import {Helmet} from 'react-helmet';
import NewUpdate from './components/NewUpdate';

import '../assets/scss/App.scss';

const history = createBrowserHistory();
history.listen(function (location) {    
    window.scrollTo(0, 0);
});

class App extends Component {
    render(){
        return (
            <Router history={history}>
                <Helmet>
    
                </Helmet>
                <div className="App" style={{padding: "0px 10px", maxWidth: "500px", textAlign: "left", margin: "0px auto", marginTop: "30px", color: "#000"}}>
                    <div>Welcome to CloudPremise.com.</div><br/>

                    <div>I create software solutions.  Many shapes and sizes.</div><br/>

                    <div>If you found this page you might be needing your own software creation.</div><br/>

                    <div>Join the long list of happy customers that include:</div><br/>

                    Nissan Motors<br/>
                    Salesforce.com<br/>
                    HCA Healthcare<br/>
                    Compassion International<br/>
                    Professional Case Management<br/>
                    Vemo Education<br/>
                    Smearch<br/>
                    Take-Two Interactive<br/>
                    Serverless Stack<br/>

                    <div>And many others.</div><br/>

                    <div>Let’s create something amazing together!</div><br/>

                    <div><a href="mailto:greg@cloudpremise.com">greg@cloudpremise.com</a></div>

                    <NewUpdate  />
                </div>
            </Router>      
        );
    }
}

export default App;
